import React from 'react'
import { Helmet } from 'react-helmet'

import useSiteMetadata from './SiteMetadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import 'flexboxgrid'
import 'tachyons'

import './all.sass'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://amanofoods.ca/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://amanofoods.ca/img/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://amanofoods.ca/img/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="https://amanofoods.ca/img/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="https://amanofoods.ca/img/safari-pinned-tab.svg"
          color="#000000"
        />
        <link
          rel="shortcut icon"
          href="https://amanofoods.ca/img/favicon.ico"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Karla:400,700&display=swap"
          rel="stylesheet"
        />

        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta
          name="msapplication-config"
          content="https://amanofoods.ca/img/browserconfig.xml"
        />
        <meta name="theme-color" content="#000000" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content="https://amanofoods.ca/img/social.jpg"
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
