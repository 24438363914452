import React from 'react'

// Social
import instagram from '../img/social/instagram.svg'
// import pinterest from '../img/social/pinterest.svg'
// import facebook from '../img/social/facebook.svg'
// import linkedin from '../img/social/linkedin.svg'
// import twitter from '../img/social/twitter.svg'

const social = [
  // {
  //   image: facebook,
  //   title: 'Facebook',
  //   url: 'https://facebook.com/amano_foods',
  //   key: 0
  // },
  // {
  //   image: twitter,
  //   title: 'Twitter',
  //   url: 'https://twitter.com/amano_foods',
  //   key: 1
  // },
  // {
  //   image: pinterest,
  //   title: 'Pinterest',
  //   url: 'https://pinterest.com/amano_foods',
  //   key: 2
  // },
  // {
  //   image: linkedin,
  //   title: 'LinkedIn',
  //   url: 'https://linkedin.com/company/amano_foods',
  //   key: 3
  // },
  {
    image: instagram,
    title: 'Instagram',
    url: 'https://instagram.com/amano_foods',
    key: 0
  }
]

const year = new Date().getFullYear()

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="dib relative w-100 footer">
        <div className="db center mw8 ph3 pt5 pb6">
          <div className="row items-top w-100">
            <div className="col-xs-12 col-md-9">
              <strong className="dib relative w-100 f4">Amano Foods</strong>
              <p className="dib relative measure gray fw4 f6 mb4 mb0-ns">
                &copy; {year} Amano Foods Ltd.
              </p>
            </div>
            <div className="col-xs-12 col-md-3 tr">
              {social.map(object => (
                <a
                  className="dib relative v-mid w1 ml0 ml3-ns mr3 mr0-ns"
                  target="_blank"
                  href={object.url}
                  rel="noopener noreferrer"
                  key={object.key}
                >
                  <img
                    className="dib relative w-100 fit"
                    src={object.image}
                    alt={object.title}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
