import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const LinkClass =
  'bg-transparent bn link dim dib relative w-100 w-auto-l inherit pa2 pv0-l ph2'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      isTop: true
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    })
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu = () => {
    if (this.state.menuOpen) {
      this.setState({
        menuOpen: false
      })
    }
  }

  render() {
    const Logo = () => (
      <AnchorLink
        className="dib relative w-100 w-auto-l link dim inherit f4 b pa2 pa0-l ttu"
        title="Logo"
        href="#home"
      >
        Amano Foods
      </AnchorLink>
    )
    const MiddleNav = () => (
      <div className="dib relative w-100 pa2 tc">
        <AnchorLink
          className={LinkClass}
          onClick={this.closeMenu}
          offset="50"
          href="#products"
        >
          Products
        </AnchorLink>
        <AnchorLink
          className={LinkClass}
          onClick={this.closeMenu}
          offset="50"
          href="#about"
        >
          About
        </AnchorLink>
        <button className={`${LinkClass} dn-l`} onClick={this.closeMenu}>
          Close
        </button>
      </div>
    )
    return (
      <nav
        aria-label="main-navigation"
        className={`navbar w-100 fixed left-0 top-0 white f5 z-2 ${
          !this.state.isTop ? 'bg-black' : ''
        }`}
        role="navigation"
      >
        {/* Mobile nav */}
        <div
          className={`dib bg-black fixed z-3 w-100 h-100 mobile pa4 ${
            this.state.menuOpen ? 'open' : ''
          }`}
        >
          <MiddleNav />
        </div>
        {/* Non-mobile nav */}
        <div className="db center mw8 ph3 pv0 pv3-l">
          <div className="flex w-100 justify-between items-center">
            {/* Left */}
            <Logo />
            {/* Middle */}
            <div className="dn dib-l relative v-mid tc">
              <MiddleNav />
            </div>
            {/* Right */}
            <div className="dib dn-l relative w-auto">
              <button
                className="dib relative h2 w2 bn bg-transparent"
                onClick={this.toggleMenu}
              >
                <img
                  className="dib relative w-100 h-100"
                  src="/img/menu.svg"
                  alt="Menu"
                />
              </button>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
